import * as React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const header = (
    <>
      <Link className='header-link-home' to='/'>
        {title}
      </Link>
      <Link
        to='/search'
        className='header-search-box'
      >
        <div>
          <FontAwesomeIcon
            style={{ margin: 'auto 10px' }}
            icon={faSearch}
          />
        </div>
      </Link>
    </>
  )

  return (
    <div className='global-wrapper' data-is-root-path={isRootPath}>
      <header className='global-header'>
        {header}
      </header>
      <main>{children}</main>
      <footer>
        <div>
          <small>
            © 2018–{new Date().getFullYear()} typememo.jp
          </small>
        </div>
        <div>
          <small>
            <Link to='/privacy-policy'>
              Privacy Policy
            </Link>
          </small>
        </div>
        <div>
          <small>
            Thanks <a href='https://www.gatsbyjs.com'>GatsbyJS</a> and <a href='https://www.netlify.com/'>Netlify</a>
          </small>
        </div>
      </footer>
    </div>
  )
}

export default Layout
